<template>
  <div>
    <v-progress-linear
      color="EoleBlue"
      :indeterminate="progressState"
      :value="progressBarValue"
      buffer-value="100"
    ></v-progress-linear>
    <div class="register-wrapper register-v1">
      <div class="register-inner">
        <v-row>
          <v-col class="d-flex" cols="12" sm="10" md="4">
            <v-stepper elevation="0" v-model="e1" vertical>
              <v-stepper-step :complete="e1 > 1" step="1"> Introduction </v-stepper-step>
              <v-stepper-step :complete="e1 > 2" step="2"> Enregistrez votre numéro de siret </v-stepper-step>
              <v-stepper-step :complete="e1 > 3" step="3"> Identifiez-vous </v-stepper-step>
              <v-stepper-step :complete="e1 > 4" step="4"> Vos données </v-stepper-step>
              <v-stepper-step :complete="e1 > 5" step="5"> Récapitulatif de vos données </v-stepper-step>
              <v-stepper-step :complete="e1 > 6" step="6"> Validation de votre compte </v-stepper-step>
              <v-stepper-step :complete="e1 > 7" step="7"> Connectez-vous ! </v-stepper-step>
            </v-stepper>
          </v-col>

          <v-col class="d-flex" cols="12" md="8"
            ><v-card>
              <v-card-title class="d-flex align-center justify-center">
                <router-link to="/" class="d-flex align-center">
                  <v-img
                    :src="require('@/assets/images/logos/anavel_logo.png')"
                    max-height="175px"
                    max-width="80px"
                    alt="logo"
                    contain
                  ></v-img>
                </router-link>
              </v-card-title>

              <v-card-text class="d-flex align-center justify-center">
                <v-stepper v-model="e1" elevation="0">
                  <v-stepper-items>
                    <v-stepper-content :step="1">
                      <v-card-text class="text-center">
                        <p class="text-2xl text-center font-weight-semibold text--primary mb-2">
                          Bienvenue sur l'outil « Mes Services Assurances »
                        </p>
                        <small>Cet outil est développé par la société C2T Assurances</small>
                        <br />
                        <small>Page de création compte client</small>
                      </v-card-text>
                      <v-card-text>
                        <v-img
                          class="mx-auto"
                          contain
                          max-height="200px"
                          max-width="100px"
                          :src="require(`@/assets/images/pictogram/register/sign_up1.png`)"
                        ></v-img>
                      </v-card-text>
                      <v-card-actions class="justify-center">
                        <v-btn small class="white--text" color="EoleYellow" @click="nextStep(1)"> Page suivante </v-btn>
                      </v-card-actions>
                    </v-stepper-content>
                    <v-stepper-content :step="2" class="text-center">
                      <h2>Quel est votre société ?</h2>
                      <v-img
                        class="mx-auto"
                        contain
                        max-height="200px"
                        max-width="100px"
                        :src="require(`@/assets/images/pictogram/register/sign_up2.png`)"
                      ></v-img>
                      <p>
                        Munissez-vous de votre numéro de siret, rentrez-le dans le champ de formulaire ci-dessous afin
                        que l'on puisse récupérer les informations émanant de votre société.
                      </p>
                      <div>
                        <v-form ref="formSiretInformations" v-model="validityFormSiretInformations" lazy-validation>
                          <v-text-field
                            :rules="[siretRules.required, siretRules.min]"
                            :error="errorSiret"
                            :error-messages="errorSiretMessage"
                            class="inputSive mx-auto mt-2 mb-2"
                            type="number"
                            counter="14"
                            outlined
                            v-model="siretRegister"
                            label="Entrez votre n° de siret"
                            prepend-inner-icon="mdi-briefcase"
                          ></v-text-field>
                        </v-form>

                        <v-btn
                          :loading="loadingSiret"
                          :disabled="loadingSiret || siretRegister.length != 14"
                          right
                          class="white--text"
                          color="EoleYellow"
                          @click="
                            () => {
                              validateFormSiretInformations()
                            }
                          "
                          >Page suivante<v-icon right>mdi-chevron-right</v-icon></v-btn
                        >
                      </div>
                      <loading
                        transition="fade"
                        :active.sync="loadingSiret"
                        :can-cancel="false"
                        :is-full-page="false"
                        loader="dots"
                        color="#efb639"
                        :opacity="0.5"
                      >
                      </loading>
                    </v-stepper-content>
                    <v-stepper-content :step="3" class="text-center">
                      <h1>Qui êtes-vous ?</h1>
                      <v-img
                        class="mx-auto"
                        contain
                        max-height="200px"
                        max-width="100px"
                        :src="require(`@/assets/images/pictogram/register/sign_up3.png`)"
                      ></v-img>
                      <p>Merci d'identifier la personne qui sera propriétaire du compte pour votre société :</p>

                      <v-select
                        class="w-10"
                        no-data-text="Aucune données disponible"
                        v-model="directorName"
                        :items="directorIdentities"
                        label="Nom du propriétaire du compte"
                        dense
                        outlined
                      ></v-select>
                      <v-btn
                        :disabled="directorName === null"
                        class="white--text"
                        color="EoleYellow"
                        @click="
                          () => {
                            nextStep(3)
                            fetchInsurers()
                          }
                        "
                        >Page suivante {{ directorIdentities.length }}<v-icon right>mdi-chevron-right</v-icon></v-btn
                      >
                    </v-stepper-content>
                    <v-stepper-content :step="4" class="text-center">
                      <v-card-text v-if="dataPappers">
                        <p>Nous avons récupéré ces informations concernant votre société :</p>
                        <strong>
                          <p>{{ pappersInformations[0].nom_entreprise }}</p>
                        </strong>
                        <p>Vous pouvez effectuer une correction si l'une des informations suivantes est érronées</p>
                        <v-form ref="formClientInformations" v-model="validityFormClientInformations" lazy-validation>
                          <v-text-field
                            class="mt-2 mb-2"
                            outlined
                            v-model="pappersInformations[0].nom_entreprise"
                            label="Nom de la société"
                            prepend-inner-icon="mdi-briefcase"
                          ></v-text-field>
                          <v-text-field
                            class="mb-2"
                            outlined
                            :rules="emailRules"
                            v-model="email"
                            label="E-mail"
                            required
                            prepend-inner-icon="mdi-email"
                          ></v-text-field>
                          <v-text-field
                            class="mb-2"
                            outlined
                            v-model="password"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[passwordRules.required, passwordRules.min]"
                            :type="showPassword ? 'text' : 'password'"
                            label="Mot de passe"
                            hint="8 caractères minimum"
                            counter
                            @click:append="showPassword = !showPassword"
                          ></v-text-field>

                          <v-text-field
                            class="mb-2"
                            outlined
                            v-model="passwordValidation"
                            :error-messages="passwordValidationMessage"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[passwordRules.required, passwordRules.min]"
                            :type="showPassword ? 'text' : 'password'"
                            label=" Validation du mot de passe"
                            hint="Vos deux mots de passe doivent correspondre"
                            counter
                            @click:append="showPassword = !showPassword"
                          ></v-text-field>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-checkbox v-model="userState" label="Agent Général" value="agent"></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-checkbox v-model="userState" label="Courtier" value="courtier"></v-checkbox>
                            </v-col>
                          </v-row>

                          <div v-if="userState.includes('agent')">
                            <p>Vous avez choisi Agent Général. Pour quelle compagnie travaillez-vous ?</p>
                            <v-select
                              class="mb-2"
                              :rules="agentSelectedCompanyNameRules"
                              v-model="agentSelectedCompanyName"
                              :items="agentCompanyName"
                              item-text="name"
                              item-value="id"
                              label="Nom de la compagnie"
                              dense
                              outlined
                            ></v-select>
                          </div>
                        </v-form>

                        <v-btn
                          class="white--text"
                          color="EoleYellow"
                          @click="
                            () => {
                              validateFormClientInformations()
                            }
                          "
                          >Page suivante<v-icon right>mdi-chevron-right</v-icon></v-btn
                        >
                      </v-card-text>
                    </v-stepper-content>
                    <v-stepper-content :step="5" class="text-center">
                      <v-card-text v-if="dataPappers">
                        <h1>Récapitulatif</h1>
                        <v-divider class="ma-2"></v-divider>
                        <p>Vous vous inscrivez pour le compte de la société :</p>
                        <p>
                          <v-icon small>mdi-chevron-right</v-icon>
                          <strong>{{ pappersInformations[0].nom_entreprise }}</strong>
                        </p>
                        <p>Le nom du propriétaire du compte :</p>
                        <p>
                          <v-icon small>mdi-chevron-right</v-icon>
                          <strong>{{ directorName }}</strong>
                        </p>
                        <p>L'adresse email de contact :</p>
                        <p>
                          <v-icon small>mdi-chevron-right</v-icon>
                          <strong>{{ email }}</strong>
                        </p>
                        <v-divider class="mb-2"></v-divider>
                        <div v-if="userState.includes('agent')">
                          <p>
                            <v-icon color="EoleGreen" small>mdi-check</v-icon>
                            Vous agissez en tant qu'agent général {{ agentSelectedCompanyName }}
                          </p>
                        </div>
                        <div v-else>
                          <p>
                            <v-icon color="EoleError" small>mdi-close</v-icon>
                            Vous n'agissez pas en tant qu'agent général
                          </p>
                        </div>
                        <div v-if="userState.includes('courtier')">
                          <p><v-icon color="EoleGreen" small>mdi-check</v-icon> Vous agissez en tant que courtier</p>
                        </div>
                        <div v-else>
                          <p>
                            <v-icon color="EoleError" small>mdi-close</v-icon>
                            Vous n'agissez pas en tant que courtier
                          </p>
                        </div>
                        <div class="justify-center text-center">
                          <v-btn
                            class="white--text"
                            color="EoleGreen"
                            @click="
                              () => {
                                nextStep(5)
                                generateExpectedOTP()
                              }
                            "
                            >Valider mes informations<v-icon right>mdi-check</v-icon></v-btn
                          >
                        </div>
                      </v-card-text>
                    </v-stepper-content>

                    <v-stepper-content step="6" class="text-center">
                      <v-card-text class="text-center" v-if="dataPappers">
                        <h1>Validation de l'email</h1>
                        <v-divider class="ma-2"></v-divider>
                        <p>
                          Madame Monsieur <strong>{{ directorName }}</strong
                          >,
                        </p>
                        <p>
                          Nous venons de vous envoyer un mail à l'adresse suivante :
                          <strong>{{ email }}</strong>
                        </p>
                        <p>
                          Merci d'activer votre compte en rentrant le code reçu par email dans le champs ci-dessous.
                        </p>
                        <v-chip class="ma-5" color="EoleYellow" outlined
                          ><v-icon>mdi-timer-sand</v-icon>{{ this.timerCount }} secondes</v-chip
                        >
                        <div v-if="timerCount === 0">
                          <v-alert color="error" dismissible icon="mdi-alert" text
                            >Votre code généré à expiré <a href="#">Merci de regénéré un code</a></v-alert
                          >
                        </div>
                        <v-btn v-if="timerCount === 0" class="mb-3" @click="generateExpectedOTP"
                          >Générer un nouveau code</v-btn
                        >
                        <br />
                        <div class="ma-auto position-relative">
                          <v-otp-input
                            v-model="otp"
                            :disabled="loading || codeExpire === true"
                            @finish="onFinish"
                          ></v-otp-input>
                        </div>
                      </v-card-text>
                    </v-stepper-content>

                    <v-stepper-content :step="7" class="text-center">
                      <h1>Votre compte à correctement été crée !</h1>
                      <br />
                      <p>Merci pour votre inscription sur le site d'Anavel</p>
                      <p>Vous allez être redirigé d'ici quelques instant...</p>
                      <v-btn
                        :loading="loading3"
                        :disabled="loading3"
                        color="EoleYellow"
                        class="black--text"
                        rounded
                        @click="
                          () => {
                            loader = 'loading3'
                          }
                        "
                      >
                        Terminer l'inscription
                        <v-icon right> mdi-check </v-icon>
                      </v-btn>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-card-text>

              <v-card-text class="d-flex align-center mt-2">
                <v-divider></v-divider>
                <span class="mx-5">ou</span>
                <v-divider></v-divider>
              </v-card-text>

              <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                <span class="me-2"> Vous possédez déjà un compte ? </span>
                <router-link :to="{ name: 'pages-login' }"> Connectez-vous </router-link>
              </v-card-text>
              <v-overlay absolute :value="loading">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </v-overlay>
              <v-footer color="EoleBlueLighten" class="text-center justify-center black--text">
                <small class="caption EoleBlue--text"
                  >{{ appName }} {{ version }} &copy; {{ new Date().getFullYear() }}</small
                >
              </v-footer></v-card
            ></v-col
          >
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js"
import { ref } from "@vue/composition-api"
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
import config from "@/views/config/config"
// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"

init("user_gUunL4KW2ImPaGngWHYhP")
export default {
  name: "App",
  data: () => ({
    version: config.informations.version,
    appName: config.applicationName,
    agentCompanyName: [],
    passwordValidationMessage: null,
    errorSiret: false,
    errorSiretMessage: null,
    agentSelectedCompanyName: null,
    directorName: null,
    lastname: null,
    firstname: null,
    email: null,
    userState: ["agent"],
    timerCount: 59,
    createCode: false,
    siretRegister: "",
    pappersInformations: [],
    dataPappers: false,
    directorIdentities: [],
    loaderSiret: null,
    loadingSiret: false,

    /* STEPPERS */
    e1: 1,
    steps: 8,
    codeValidity: true,
    codeExpire: false,
    progressState: false,

    /* FORM DATA */
    validityFormClientInformations: true,
    validityFormId: true,
    validityFormSiretInformations: true,

    progressBarValue: 0,
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    passwordValidation: "",

    /* */
    fetchFirstname: null,
    fetchLastname: null,
    /* Password viewer */
    showPassword: false,

    directorNameRules: [v => !!v || "Prénom requis", v => v || "Prénom must be less than 10 characters"],
    agentSelectedCompanyNameRules: [v => !!v || "Compagnie requis"],

    /* Loading button */
    loading3: false,
    loader: null,
    loading: false,
    snackbar: false,
    snackbarColor: "default",
    otp: "",
    text: "",
    expectedOtp: "",
    snackbarIcon: null,

    /* */
    emailRules: [v => !!v || "E-mail requis", v => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    passwordRules: {
      required: value => !!value || "Mot de passe requis.",
      min: v => v.length >= 8 || "Minimum 8 caractères",
    },
    siretRules: {
      required: v => !!v || "N° de siret requis",
      min: v => v.length === 14 || "Le siret doit être composé de 14 chiffres",
    },
  }),
  watch: {
    loader() {
      const l = this.loader
      this[l] = !this[l]
      this.progressBarValue = 0
      this.progressState = true
      setTimeout(() => {
        this.$router.push("/auth/complete")
      }, 6000)
    },
  },
  components: {
    Loading,
  },
  computed: {
    isActive() {
      return this.otp.length === this.length
    },
  },
  methods: {
    isDisable() {
      return this.siretRegister.length === 14
    },
    // ? Method to get all insurers in Eole API
    async verifyAgencies(siret) {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/search/${siret}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (data.code === 1) {
          this.errorSiretMessage = "Le siret est déjà enregistré dans nos bases"
          this.loadingSiret = false
        } else {
          // this.fetchJson()
          this.fetchPappers(siret)
          this.loadingSiret = false
        }
      } catch (e) {
        console.log(e)
      }
    },
    // ? Method to get all insurers in Eole API
    async fetchInsurers() {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/insurers/approved`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.forEach(element => {
          this.agentCompanyName.push({ id: element.id, name: element.name })
        })
      } catch (e) {
        console.log(e)
      }
    },
    // ?  Method to get register informations of society in database
    async registerMasterAccount(email, agencyName, siret, password, insurerId) {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let arrayName = this.directorName.split(" ")
        let lastname = arrayName[0]
        let firstname = arrayName[1]

        const response = await fetch(
          `${config.apiUri}/php/pages/register.php?name=${agencyName}&siret=${siret}&email=${email}&lastname=${lastname}&firstname=${firstname}&password=${password}&insurerId=${insurerId}`,
          {
            mode: "cors",
            method: "POST",
            headers: headers,
          },
        )
      } catch (e) {
        console.log(e)
      }
    },
    // ? (FOR TEST ONLY) Method to get all informations of society in data.json file
    // ? Located at : http://localhost/json/data.json
    async fetchJson() {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`http://192.168.4.156/json/index.php`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        let data = await response.json()
        this.pappersInformations.push(data)
        this.pappersInformations[0].representants.forEach(element => {
          let allFirstName = element.prenom.split(",")
          let firstname = allFirstName[0]
          this.directorIdentities.push(`${firstname} ${element.nom}`)
        })

        this.directorName = this.directorIdentities[0]
        this.dataPappers = true
      } catch (e) {
        console.log(e)
        this.errorSiret = true
        this.errorSiretMessage = "Impossible de lire le numéro de siret"
      }
    },
    // ? Method to get informations using an API (https://api.pappers.fr/)
    async fetchPappers(siret) {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `https://api.pappers.fr/v2/entreprise?api_token=${config.tokenPappers}&siret=${siret}`,
          {
            mode: "cors",
            method: "GET",
            headers: headers,
          },
        )
        let data = await response.json()

        if (data.hasOwnProperty("error")) {
          this.errorSiretMessage = `Nous n'avons pas trouvé d'entreprise avec le numéro de siret ${this.siretRegister}`
        } else {
          this.pappersInformations.push(data)

          this.pappersInformations[0].representants.forEach(element => {
            let allFirstName = element.prenom.split(",")
            let firstname = allFirstName[0]
            this.directorIdentities.push(`${firstname} ${element.nom}`)
          })

          this.directorName = this.directorIdentities[0]
          this.dataPappers = true

          this.nextStep(2)
        }
      } catch (e) {
        console.log(e)
      }
    },
    // ? Method to go in next step of steppers
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1
      } else {
        this.e1 = n + 1
        this.progressBarValue += 14.28
      }
    },
    // ? Method to go in last step of steppers
    lastStep(n) {
      this.e1 = n - 1
    },

    generateOTP() {
      // Declare a digits variable
      // which stores all digits
      var digits = "0123456789"
      let OTP = ""
      for (let i = 0; i < 6; i++) {
        OTP += digits[Math.floor(Math.random() * 10)]
      }
      return OTP
    },
    sendmail(email, msg, directorName) {
      try {
        emailjs.send("service_5p49t7p", "template_k77uz0r", {
          to_name: directorName,
          //from_name: email,
          to_email: email,
          message: msg,
        })
      } catch (error) {
        console.log(error)
      }
    },
    onFinish(rsp) {
      this.loading = true
      setTimeout(() => {
        this.loading = false

        if (rsp == this.expectedOtp) {
          this.codeValidity = false
          this.e1++
          this.progressBarValue += 50

          this.registerMasterAccount(
            this.email,
            this.pappersInformations[0].nom_entreprise,
            this.siretRegister,
            this.password,
            this.agentSelectedCompanyName,
          )

          this.$toast.success("Le code de sécurité est correct", {
            position: "bottom-right",
            timeout: 3000,
          })
        } else {
          this.codeValidity = true
          this.$toast.error("Le code de sécurité est incorrect", {
            position: "bottom-right",
            timeout: 3000,
          })
        }
      }, 4000)
    },
    countDownTimer() {
      if (this.timerCount > 0) {
        setTimeout(() => {
          this.timerCount -= 1
          this.countDownTimer()
        }, 1000)
      }
      if (this.timerCount === 0) {
        this.codeExpire = true
      }
    },

    generateExpectedOTP() {
      this.expectedOtp = this.generateOTP()

      //? If OTP code has expired the code has changed and the timercount is renouveled
      if (this.codeExpire === true) {
        this.timerCount = 59
        this.codeExpire = false
      }
      this.countDownTimer()
      //! Sending mail by using emailJS
      this.sendmail(this.email, this.expectedOtp, this.directorName)
    },

    validateFormSiretInformations() {
      this.loadingSiret = true
      if (this.siretRegister.length === 14) {
        if (this.$refs.formSiretInformations.validate()) {
          this.verifyAgencies(this.siretRegister)
        }
      } else {
        this.loadingSiret = false
      }
    },

    validateFormClientInformations() {
      if (this.password === this.passwordValidation) {
        if (this.$refs.formClientInformations.validate()) {
          this.progressBarValue += 50
          this.nextStep(4)
        }
      } else {
        this.passwordValidationMessage = "Les mots de passes doivent correspondre"
      }
    },

    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
  },

  setup() {
    const isPasswordVisible = ref(false)
    const username = ref("")
    const email = ref("")
    const password = ref("")
    const directorName = ref("")
    const agentSelectedCompanyName = ref("")

    return {
      isPasswordVisible,
      username,
      email,
      password,
      directorName,
      agentSelectedCompanyName,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/register.scss";

.inputSive {
  width: 50%;
}

@media (max-width: 600px) {
  .inputSive {
    width: 100%;
  }
}
</style>
